import React from 'react';
import './small-loading.scss';

interface IProps {
    isLargeLoading?: boolean;
}

const SmallLoading = ({ isLargeLoading }: IProps) => {
    return (
        <div className={'small-loading-wrapper'}>
            <div className={`${isLargeLoading ? 'large-loading' : 'small-loading-wrap'}`} />
        </div>
    );
};

export default SmallLoading;
