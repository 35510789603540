export enum UserType {
    DOCTOR = 'DOCTOR',
    ADMIN = 'ADMIN'
};

export enum ModalType {
    TABS = 'TABS',
    DOTS = 'DOTS',
    STATUS = 'STATUS',
    FILTER = 'FILTER',
    SOLICITATION = 'SOLICITATION',
    RESOLVE_SOLICITATION = 'RESOLVE_SOLICITATION',
    RESOLVE_NOT_REGISTERED = 'RESOLVE_NOT_REGISTERED',
    RESOLVE_CORRECTION = 'RESOLVE_CORRECTION',
    RESOLVE_CONTESTED = 'RESOLVE_CONTESTED',
    CONCLUDE_ACCESS = 'CONCLUDE_ACCESS',
    COMPLETE_ACCESS = 'COMPLETE_ACCESS',
    CANCELED = 'CANCELED'
};
  
export enum Tag {
    OK = 'OK',
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    ADJUSTED = 'ADJUSTED',
    CONTESTED = 'CONTESTED',
    CORRECTION = 'CORRECTION',
    ATTENDANCE = 'ATTENDANCE',
    PROGRAMMED = 'PROGRAMMED',
    ADJUSTED_ADMIN = 'ADJUSTED_ADMIN',
    NOT_REGISTERED = 'NOT_REGISTERED',
    ADJUSTED_DOCTOR = 'ADJUSTED_DOCTOR',
    CANCELED = 'CANCELED',
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE'
};