import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Contract } from '../../../../model/contract';
import ImportationService from '../../../../services/importation-service';
import './autocomplete-code-search.scss';
import { Pageable } from '../../../../model/pageable';

export interface AutocompleteProps {
    onChange?: (event: ChangeEvent<{}>, doctor: any) => void;
}

const AutocompleteCodeSearch = ({ onChange }: AutocompleteProps): JSX.Element => {
    const [timeOutSearch, setTimeOutSearch] = useState<NodeJS.Timeout | null>(null);
    const [searchCode, setSearchCode] = useState<string>('');
    const [searchCodeSendApi, setSearchCodeSendApi] = useState<string>('');
    const [contracts, setContracts] = useState<Contract[]>([]);

    const [pageable, setPageable] = useState<Pageable>({
        size: 10,
        page: 0,
        totalPages: 0
    });

    useEffect(() => {
        getResultsCenter();
    }, [searchCodeSendApi, pageable.page]);

    const getResultsCenter = async () => {
        await ImportationService.getResultsCenter(pageable, searchCode).then(result => {
            if (contracts.length > 0) {
                setContracts([...contracts, ...result.content]);
            } else {
                setContracts(result.content);
            }

            setPageable({ ...pageable, totalPages: result.totalPages });
        });
    };

    const handleOnChange = (value: string) => {
        pageable.page = 0;
        setContracts([]);

        setSearchCode(value);

        if (timeOutSearch != null) {
            clearTimeout(timeOutSearch);
        }

        setTimeOutSearch(
            setTimeout(() => {
                setSearchCodeSendApi(value);
            }, 500)
        );
    };

    const handleInput = ({ params }): ReactNode => {
        return <TextField {...params} value={searchCode} variant="filled" />;
    };

    return (
        <Autocomplete
            id="search-code"
            freeSolo
            clearText=""
            onChange={onChange}
            options={contracts ?? []}
            renderInput={params => handleInput({ params })}
            className={'floating-label-custom-autocomplete-code'}
            getOptionLabel={option => `${option.resultsCenter}`}
            inputValue={searchCode ?? ''}
            onInputChange={(event, inputValue) => handleOnChange(inputValue)}
            PaperComponent={({ children }) => (
                <div className="style-listbox">
                    {children}
                </div>
            )}
            ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                    const listboxNode = event.currentTarget;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                        if (pageable.page! + 1 < pageable.totalPages!) {
                            setPageable({ ...pageable, page: pageable.page! + 1 });
                        }
                    }
                },
                style: { height: '160px' }
            }}
        />
    );
};

export default AutocompleteCodeSearch;
