import { AxiosResponse } from 'axios';
import { IAdminReport, ISavedFilter } from '../model/admin-report';
import { Pageable, PageableResponse } from '../model/pageable';
import { Predicate } from '../model/predicate';
import { requestParamsFormatter } from '../util/api-utils';
import { api } from './api.new';
import { PredicateOperators } from '../model/predicate-operators';
import { requestPredicateOperatorsFormatter } from '../util/predicate-operators-utils';

class AdminReportApi {
    static getAllAdminReportData = (
        predicate: Predicate,
        page: Pageable,
        isOnCallsWithDoctor: boolean,
        doctorsCleanFilter: boolean,
        onCallsCleanFilter: boolean,
        schedulesCleanFilter: boolean,
        contractsCleanFilter: boolean,
        orderByFields?: string[],
        orderType?: string,
        predicateOperators?: PredicateOperators[]
    ): Promise<AxiosResponse<PageableResponse<IAdminReport>>> => {
        const operators = requestPredicateOperatorsFormatter(predicateOperators);
        const [pageParams, filterParams] = requestParamsFormatter(page, predicate);
        return api.get<PageableResponse<IAdminReport>>(
            `/contract-report?onCallsWithDoctor=${isOnCallsWithDoctor}&doctorsCleanFilter=${doctorsCleanFilter}&onCallsCleanFilter=${onCallsCleanFilter}&schedulesCleanFilter=${schedulesCleanFilter}&contractsCleanFilter=${contractsCleanFilter}&${pageParams}&${filterParams}&${operators}orderByFields=${orderByFields ??
                ''}&orderType=${orderType ?? ''}`
        );
    };

    static saveReport = (data: ISavedFilter): Promise<AxiosResponse<ISavedFilter>> => {
        return api.post<ISavedFilter>('/report-filter', data);
    };

    static getAllReportsSaved = (predicate: Predicate, page: Pageable): Promise<AxiosResponse<PageableResponse<ISavedFilter>>> => {
        const [pageParams, filterParams] = requestParamsFormatter(page, predicate);
        return api.get<PageableResponse<ISavedFilter>>(`/report-filter?${pageParams}&${filterParams}`);
    };

    static deleteReportSaved = (id: number): Promise<AxiosResponse> => {
        return api.delete(`/report-filter/${id}`);
    };

    static getReportCsv = (
        predicate: Predicate,
        page: Pageable,
        isOnCallsWithDoctor: boolean,
        doctorsCleanFilter: boolean,
        onCallsCleanFilter: boolean,
        schedulesCleanFilter: boolean,
        contractsCleanFilter: boolean,
        orderByFields?: string[],
        orderType?: string,
        predicateOperators?: PredicateOperators[]
    ): Promise<AxiosResponse<PageableResponse<string>>> => {
        const operators = requestPredicateOperatorsFormatter(predicateOperators);
        const [pageParams, filterParams] = requestParamsFormatter(page, predicate);
        return api.get<PageableResponse<string>>(
            `contract-report/csv?onCallsWithDoctor=${isOnCallsWithDoctor}&doctorsCleanFilter=${doctorsCleanFilter}&onCallsCleanFilter=${onCallsCleanFilter}&schedulesCleanFilter=${schedulesCleanFilter}&contractsCleanFilter=${contractsCleanFilter}&${pageParams}&${filterParams}&${operators}orderByFields=${orderByFields ??
                ''}&orderType=${orderType ?? ''}`
        );
    };
}

export default AdminReportApi;
