import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@material-ui/core';
import SimpleOrderTable, { ColumnSort } from '../../../../components/simple-ordered-table/simple-ordered-table';
import { Tab } from '../';
import { ModalType } from '../../../../model/enums/admin-report';
import { APP_LOCAL_DATE_FORMAT } from '../../../../config/constants';
import '../../../../components/main.scss';
import '../styles.scss';
import { Pageable } from '../../../../model/pageable';
import AdminReportService from '../../../../services/admin-report.service';
import { ISavedFilter } from '../../../../model/admin-report';

interface IProps {
    handleShowModal: (type: ModalType) => void;
    showDotsModal: boolean;
    setShowDotsModal: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveTab: React.Dispatch<React.SetStateAction<Tab>>;
    setSelectedOption: React.Dispatch<any>;
    setIsFirstRender: () => void;
}

const AdminReportList = ({ handleShowModal, showDotsModal, setShowDotsModal, setActiveTab, setSelectedOption, setIsFirstRender }: IProps) => {
    const { t } = useTranslation();
    const [anchorDots, setAnchorDots] = useState<(EventTarget & HTMLDivElement) | null>(null);
    const [currentReport, setCurrentReport] = useState<ISavedFilter>();

    const [sort, setSort] = useState<string>('name,desc');

    const pageDefault = {
        page: 0,
        size: 10,
        totalPages: 0
    };

    const [pagination, setPagination] = useState<Pageable>({ ...pageDefault });
    const [savedFilters, setSavedFilters] = useState<ISavedFilter[]>([]);

    useEffect(() => {
        getReportsList();
    }, [pagination.page, sort]);

    const getReportsList = () => {
        const predicate = {
            search: '',
            sort
        };

        AdminReportService.getAllReportsSaved(predicate, pagination).then(result => {
            setSavedFilters(result.content);
            setPagination({ ...pagination, totalPages: result.totalPages });
        });
    };

    const handleOnClickRow = (index: number) => {
        setIsFirstRender();
        setSelectedOption(savedFilters?.[index]);
        setActiveTab(Tab.ADMIN_REPORT);
    };

    const onDeleteReport = () => {
        AdminReportService.deleteReportSaved(currentReport?.id!).then(() => {
            getReportsList();
            setShowDotsModal(false);
        });
    };

    const handleTransformToTableContent = (content?: ISavedFilter[]) => {
        if (content == null || content.length === 0) return [];

        return content.map((item, index) => [
            item.name?.toUpperCase() ?? '',
            item.incUserDate ? moment(item.incUserDate).format(APP_LOCAL_DATE_FORMAT) : '',
            item.updateUserDate ? moment(item.updateUserDate).format(APP_LOCAL_DATE_FORMAT) : '',
            <div key={index}>
                <div
                    style={{ margin: 'auto 15px auto auto' }}
                    key={index}
                    aria-haspopup="true"
                    className="icon-dots"
                    aria-controls="simple-menu"
                    onClick={({ currentTarget }) => {
                        setAnchorDots(currentTarget);
                        setCurrentReport(item);
                        handleShowModal(ModalType.DOTS);
                    }}
                />
            </div>
        ]);
    };

    const updatePage = (newPage: number) => {
        setPagination({ ...pagination, page: newPage });
    };

    const tableHeaders: ColumnSort[] = [
        { name: t('report.admin.table.reportName'), sortCode: 'name' },
        { name: t('report.admin.table.creationDate'), sortCode: 'incUserDate' },
        { name: t('report.admin.table.modifiedDate'), sortCode: 'updateUserDate' },
        { sortDisabled: true }
    ];

    const rows = handleTransformToTableContent(savedFilters);

    return (
        <div>
            <div className="report__container--subtitle"> {t('report.admin.subtitleSecond')}</div>
            <div className="report__container--table scroll-table padding-page" style={{ padding: '0px' }}>
                <SimpleOrderTable
                    rows={rows}
                    page={pagination}
                    totalPages={pagination.totalPages}
                    columnNameKeys={tableHeaders}
                    onChangePage={updatePage}
                    onSort={setSort}
                    onClickRow={(index: number) => handleOnClickRow(index)}
                />
            </div>
            <Menu className="tooltip-style" anchorEl={anchorDots} keepMounted open={showDotsModal} onClose={() => setShowDotsModal(false)}>
                <MenuItem onClick={() => onDeleteReport()}>{t('global.button.delete')}</MenuItem>
            </Menu>
        </div>
    );
};

export default AdminReportList;
