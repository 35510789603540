import React, { useState, useEffect, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FilterModal from '../filter-modal';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Container, Checkbox, Tooltip } from '@material-ui/core';
import { Pageable } from '../../model/pageable';
import './styles.scss';
import AdminReportService from '../../services/admin-report.service';
import { Predicate } from '../../model/predicate';
import { ISavedFilter } from '../../model/admin-report';

interface IProps {
    anchorEl: any;
    showModal: boolean;
    onCloseModal: () => void;
    savedFiltersSelected: ISavedFilter | null;
    setSavedFiltersSelected: React.Dispatch<any>;
}

const SelectInfinity = ({ anchorEl, showModal, onCloseModal, savedFiltersSelected, setSavedFiltersSelected }: IProps) => {
    const [t] = useTranslation();

    const pageDefault = {
        page: 0,
        size: 10,
        totalPages: 0,
        sort: 'id,asc'
    };

    const [pagination, setPagination] = useState<Pageable>({ ...pageDefault });
    const [predicate, setPredicate] = useState<Predicate>({ search: '' });
    const [savedFilters, setSavedFilters] = useState<any[]>([]);

    const [inputValue, setInputValue] = useState<string>('');
    const [timeOutSearch, setTimeOutSearch] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (!showModal) {
            setInputValue('');
            setPredicate({ search: '' });
            setPagination({ ...pageDefault });
            setSavedFilters([]);
            return;
        }

        getReportsList();
    }, [showModal, pagination.page]);

    useEffect(() => {
        if (showModal) {
            getReportsList();
        }
    }, [inputValue]);

    const getReportsList = () => {
        AdminReportService.getAllReportsSaved(predicate, pagination).then(result => {
            setSavedFilters([...savedFilters, ...result.content]);
            setPagination({ ...pagination, totalPages: result.totalPages });
        });
    };

    const loadMore = async event => {
        const listboxNode = event.currentTarget;

        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            if (pagination.page! + 1 < pagination.totalPages!) {
                setPagination({ ...pagination, page: pagination.page! + 1 });
            }
        }
    };

    const handleInput = ({ params }): ReactNode => {
        return <TextField {...params} label={t('report.admin.dropdown.label.reportName')} variant="filled" multiline={false} />;
    };

    const handleOnClickOption = option => {
        if (savedFiltersSelected && savedFiltersSelected.id === option.id) {
            // uncheck option
            setSavedFiltersSelected(null);
        } else {
            setSavedFiltersSelected(option);
        }
        onCloseModal();
    };

    const handleOption = (option): ReactNode => {
        let isSelected = false;
        if (savedFiltersSelected && savedFiltersSelected.id === option.id) {
            isSelected = false;
        }

        return (
            <div
                className={isSelected ? 'checkbox-button__container--buttons-selected' : 'checkbox-button__container--buttons-unselected'}
                onClick={() => handleOnClickOption(option)}
            >
                <Checkbox style={{ marginRight: 0, paddingRight: 0 }} checked={isSelected} />
                <Tooltip title={option.name} placement="top">
                    <span>{option.name}</span>
                </Tooltip>
            </div>
        );
    };

    const handleOptionLabel = (option: ISavedFilter) => {
        return `${option.id} ${option.name}`;
    };

    const handleInputChange = (event: any, value: string) => {
        if (timeOutSearch != null) {
            clearTimeout(timeOutSearch);
        }

        setTimeOutSearch(
            setTimeout(() => {
                setSavedFilters([]);
                setPredicate({ search: value });
                setPagination({ ...pageDefault });
                setInputValue(value);
            }, 500)
        );
    };

    const fixedPopper = props => {
        return <Container {...props} className="filter-report-data__options-wrapper" />;
    };

    return (
        <div className="select-infinity__container">
            <FilterModal onCancel={onCloseModal} anchor={anchorEl} showModal={showModal} onApplyFilter={() => {}} onResetFilters={() => {}}>
                <div className="checkbox-button__container">
                    <div className="checkbox-button__container--buttons">
                        <Autocomplete
                            className={'floating-label-custom-autocomplete'}
                            freeSolo
                            clearText="Limpar"
                            options={savedFilters}
                            disablePortal={true}
                            getOptionLabel={option => handleOptionLabel(option)}
                            renderInput={params => handleInput({ params })}
                            renderOption={(option, { selected }) => handleOption(option)}
                            PopperComponent={fixedPopper}
                            multiple={true}
                            open={true}
                            onInputChange={(event, value) => handleInputChange(event, value)}
                            ListboxProps={{ onScroll: loadMore }}
                        />
                    </div>
                </div>
            </FilterModal>
        </div>
    );
};

export default SelectInfinity;
