import React from "react";

const Relatorios = () => {

    return (
     <div>
         Relatorios
     </div>
    );
}

export default Relatorios;
