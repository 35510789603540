import { AxiosError, AxiosResponse } from 'axios';
import { Pageable, PageableResponse } from '../model/pageable';
import { PredicateOperators } from '../model/predicate-operators';
import { IAdminReport, ISavedFilter } from '../model/admin-report';
import { Predicate } from '../model/predicate';
import { isResponseSuccess } from '../util/api-utils';
import ToastUtils from '../util/toast-utils';
import AdminReportApi from '../api/admin-report.api';
import StatusCode from 'http-status-codes';

class AdminReportService {
    static getAllAdminReportData = async (
        predicate: Predicate,
        pageable: Pageable,
        isOnCallsWithDoctor: boolean,
        doctorsCleanFilter: boolean,
        onCallsCleanFilter: boolean,
        schedulesCleanFilter: boolean,
        contractsCleanFilter: boolean,
        orderByFields?: string[],
        orderType?: string,
        predicateOperators?: PredicateOperators[]
    ): Promise<PageableResponse<IAdminReport>> => {
        try {
            const result: AxiosResponse<PageableResponse<IAdminReport>> = await AdminReportApi.getAllAdminReportData(
                predicate,
                pageable,
                isOnCallsWithDoctor,
                doctorsCleanFilter,
                onCallsCleanFilter,
                schedulesCleanFilter,
                contractsCleanFilter,
                orderByFields,
                orderType,
                predicateOperators
            );
            if (!isResponseSuccess(result.data != null, result.status)) {
                ToastUtils.emitErrorToast('getAllAdminReportData');
                return Promise.reject({ status: result.status, statusText: result.statusText } as AxiosResponse);
            }
            return Promise.resolve(result.data);
        } catch (error) {
            console.error(error);
            ToastUtils.emitErrorToast('getAllAdminReportData');
            return Promise.reject(error.response as AxiosResponse);
        }
    };

    static saveReport = async (data: ISavedFilter): Promise<ISavedFilter> => {
        try {
            const result: AxiosResponse<ISavedFilter> = await AdminReportApi.saveReport(data);
            if (result.status === StatusCode.CREATED && result.data != null) {
                ToastUtils.emitSuccessToast('saveReport');
                return Promise.resolve(result.data);
            }

            ToastUtils.emitErrorToast('saveReport');
            return Promise.reject({ status: result.status } as AxiosResponse);
        } catch (error) {
            const { response } = error as AxiosError;

            ToastUtils.emitErrorToast('saveReport');
            console.error(response);
            return Promise.reject(response);
        }
    };

    static getAllReportsSaved = async (predicate: Predicate, page: Pageable): Promise<PageableResponse<ISavedFilter>> => {
        try {
            const result: AxiosResponse<PageableResponse<ISavedFilter>> = await AdminReportApi.getAllReportsSaved(predicate, page);
            if (result.status === StatusCode.OK && result.data != null) {
                return Promise.resolve(result.data);
            }

            ToastUtils.emitErrorToast('reportsSaved');
            return Promise.reject({ status: result.status } as AxiosResponse);
        } catch (error) {
            const { response } = error as AxiosError;

            ToastUtils.emitErrorToast('reportsSaved');
            console.error(response);
            return Promise.reject(response);
        }
    };

    static deleteReportSaved = async (id: number): Promise<ISavedFilter> => {
        try {
            const result: AxiosResponse<ISavedFilter> = await AdminReportApi.deleteReportSaved(id);

            if (result.status === StatusCode.OK) {
                ToastUtils.emitSuccessToast('filterDeleted');
                return Promise.resolve(result.data ?? []);
            }

            ToastUtils.emitErrorToast('reportsSaved');
            return Promise.reject(({ status: result.status } as unknown) as AxiosResponse);
        } catch (error) {
            const { response } = error as AxiosError;

            ToastUtils.emitErrorToast('reportsSaved');
            console.error(response);
            return Promise.reject(response);
        }
    };

    static getReportCsv = async (
        predicate: Predicate,
        pageable: Pageable,
        isOnCallsWithDoctor: boolean,
        doctorsCleanFilter: boolean,
        onCallsCleanFilter: boolean,
        schedulesCleanFilter: boolean,
        contractsCleanFilter: boolean,
        orderByFields?: string[],
        orderType?: string,
        predicateOperators?: PredicateOperators[]
    ): Promise<PageableResponse<string>> => {
        try {
            const result: AxiosResponse<PageableResponse<string>> = await AdminReportApi.getReportCsv(
                predicate,
                pageable,
                isOnCallsWithDoctor,
                doctorsCleanFilter,
                onCallsCleanFilter,
                schedulesCleanFilter,
                contractsCleanFilter,
                orderByFields,
                orderType,
                predicateOperators
            );

            if (result.status === StatusCode.OK && result.data != null) {
                return Promise.resolve(result.data);
            }

            ToastUtils.emitErrorToast('reportsSaved');
            return Promise.reject({ status: result.status } as AxiosResponse);
        } catch (error) {
            const { response } = error as AxiosError;

            ToastUtils.emitErrorToast('reportsSaved');
            console.error(response);
            return Promise.reject(response);
        }
    };
}

export default AdminReportService;
