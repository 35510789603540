import { Box, Modal, CircularProgress, Typography } from '@material-ui/core';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './modal-circular-progress.scss';

interface IProps extends WithTranslation {
    isShowModal: boolean;
    progress: number;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%'
};

const ModalCircularProgress = ({ isShowModal, progress, t }: IProps) => {
    return (
        <Modal open={isShowModal}>
            <Box sx={style}>
                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress variant="indeterminate" size={60} style={{ color: '#149372' }} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography variant="caption" component="div" color="textPrimary">
                            {`${Math.round(progress)}%`}
                        </Typography>
                    </Box>
                </Box>
                <div className="text-loading">{t('global.loading')}</div>
            </Box>
        </Modal>
    );
};

export default withTranslation()(ModalCircularProgress);
