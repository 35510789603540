import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import DownloadImg from '../../../assets/img/svg/download2.svg';
import ArrowImg from '../../../assets/img/svg/down-arrow.svg';
import CloseImg from '../../../assets/img/svg/fechar-gray.svg';
import Footer from '../../../components/footer/footer';
import MenuPage from '../../../components/menu-page/menu-page';
import AdminReportMain from './tabs/admin-report-main';
import AdminReportList from './tabs/admin-report-list';
import IconButton from '../../../components/icon-button/icon-button';
import SelectInfinity from '../../../components/select-infinity';
import NotificationModal from '../../../components/notification-modal/notification-modal';
import SaveModal from './modals/save-modal';
import AdminReportService from '../../../services/admin-report.service';
import { ModalType, ColumnType } from '../../../model/enums/admin-report';
import { Predicate } from '../../../model/predicate';
import { Pageable } from '../../../model/pageable';
import '../../../components/main.scss';
import './styles.scss';
import ModalCircularProgress from '../../../components/modal-circular-progress/ModalCircularProgress';
import { ISavedFilter } from '../../../model/admin-report';

export enum Tab {
    ADMIN_REPORT,
    MANAGE_REPORTS
}

const AdminReport = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<Tab>(0);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
    const [showDotsModal, setShowDotsModal] = useState<boolean>(false);
    const [showSelectDropdown, setShowSelectDropdown] = useState<boolean>(false);
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    const [isShowProgressModal, setIsShowProgressModal] = useState<boolean>(false);
    const [reportData, setReportData] = useState<any[]>([]);
    const [selectedReport, setSelectedReport] = useState<ISavedFilter | null>(null);
    const anchorDrop = useRef(null);
    const [filter, setFilter] = useState<any>({});
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [orderByFields, setOrderByFields] = useState<string[]>([ColumnType.onCallDate]);
    const [orderType, setOrderType] = useState<string>('DESC');
    const [progress, setProgress] = useState<number>(100);
    const [hiddenData, setHiddenData] = useState<any>({
        doctorsId: [],
        onCallsId: [],
        schedulesId: [],
        contractsId: []
    });
    const [isOnCallsWithDoctor, setIsOnCallsWithDoctor] = useState<boolean>(false);
    const [doctorsCleanFilter, setDoctorsCleanFilter] = useState<boolean>(false);
    const [onCallsCleanFilter, setOnCallsCleanFilter] = useState<boolean>(false);
    const [schedulesCleanFilter, setSchedulesCleanFilter] = useState<boolean>(false);
    const [contractsCleanFilter, setContractsCleanFilter] = useState<boolean>(false);

    const [predicate, setPredicate] = useState<Predicate>({
        selectFields: '',
        groupByFields: '',
        doctorsId: '',
        onCallsId: '',
        schedulesId: '',
        contractsId: ''
    });

    const [pageable, setPageable] = useState<Pageable>({
        page: 0,
        size: 10,
        totalPages: 0
    });

    const tabs = [
        { name: t('report.admin.tab.report'), code: Tab.ADMIN_REPORT },
        { name: t('report.admin.tab.list'), code: Tab.MANAGE_REPORTS }
    ];

    // CHANGE COLOR FOR THIS PAGE ONLY AND CLEAN-UP WHEN LEAVING THE PAGE
    useLayoutEffect(() => {
        window.document.body.style.background = 'white';

        return () => {
            window.document.body.style.background = '';
        };
    });

    useEffect(() => {
        if (!isFirstRender) {
            getReportData();
        }
    }, [pageable]);

    useEffect(() => {
        let fields: string = '';
        let groups: string = '';

        if (filter.selectFields) {
            Object.keys(filter.selectFields).map((key, index) => {
                if (filter.selectFields[key]) {
                    fields += `${key},`;
                }
            });
            fields = fields.slice(0, -1);
        }
        if (filter.groupByFields) {
            Object.keys(filter.groupByFields).map((key, index) => {
                if (filter.groupByFields[key]) {
                    groups += `${key},`;
                }
            });
            groups = groups.slice(0, -1);
        }

        setPredicate({
            selectFields: typeof filter.selectFields === 'string' ? filter.selectFields : fields,
            groupByFields: typeof filter.groupByFields === 'string' ? filter.groupByFields : groups,
            doctorsId: filter.doctorsId,
            onCallsId: filter.onCallsId,
            schedulesId: filter.schedulesId,
            contractsId: filter.contractsId
        });
        setPageable({ ...pageable, page: 0 });
    }, [filter, orderByFields, isOnCallsWithDoctor]);

    useEffect(() => {
        if (selectedReport?.name != null) {
            setFilter({
                selectFields: selectedReport.data.fields?.join(','),
                groupByFields: selectedReport.data.groupByFields?.join(','),
                doctorsId: selectedReport.data.doctorsId?.join(','),
                onCallsId: selectedReport.data.onCallsId?.join(','),
                schedulesId: selectedReport.data.schedulesId?.join(','),
                contractsId: selectedReport.data.contractsId?.join(',')
            });

            setIsOnCallsWithDoctor(selectedReport.data.onCallsWithDoctor ?? false);
            setDoctorsCleanFilter(selectedReport.data.doctorsCleanFilter ?? false);
            setOnCallsCleanFilter(selectedReport.data.onCallsCleanFilter ?? false);
            setSchedulesCleanFilter(selectedReport.data.schedulesCleanFilter ?? false);
            setContractsCleanFilter(selectedReport.data.contractsCleanFilter ?? false);

            setHiddenData({ 
                doctorsId: selectedReport.data.doctorsId,
                onCallsId: selectedReport.data.onCallsId,
                schedulesId: selectedReport.data.schedulesId,
                contractsId: selectedReport.data.contractsId
            });

            getReportData();
        } else {
            getReportData();
        }
    }, [selectedReport]);

    const startProgressCounter = () => {
        setProgress(0);

        const timer = setInterval(() => {
            setProgress(prevProgress => prevProgress + 5);
        }, 1200);

        return () => {
            clearInterval(timer);
        };
    };

    const getReportData = () => {
        setIsShowProgressModal(true);
        startProgressCounter();

        AdminReportService.getAllAdminReportData(
            predicate,
            pageable,
            isOnCallsWithDoctor,
            doctorsCleanFilter,
            onCallsCleanFilter,
            schedulesCleanFilter,
            contractsCleanFilter,
            orderByFields,
            orderType
        ).then(result => {
            setReportData(result.content);
            if (isFirstRender) {
                setIsFirstRender(false);
                setPageable({ ...pageable, totalPages: result.totalPages });
            }

            pageable.totalPages = result.totalPages;
            setIsShowProgressModal(false);
        });
    };

    const getReportCsv = async () => {
        setIsShowProgressModal(true);
        startProgressCounter();

        await AdminReportService.getReportCsv(
            predicate,
            pageable,
            isOnCallsWithDoctor,
            doctorsCleanFilter,
            onCallsCleanFilter,
            schedulesCleanFilter,
            contractsCleanFilter,
            orderByFields,
            orderType
        ).then(response => {
            const linkSource = `data:application/csv;base64,${response}`;
            const downloadLink = document.createElement('a');
            const fileName = 'report.csv';

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }).finally(() => setIsShowProgressModal(false));
    };

    const handleShowModal = (type: ModalType) => {
        setShowSaveModal(false);
        setShowDotsModal(false);

        switch (type) {
            case ModalType.SAVE:
                setShowSaveModal(true);
                break;
            case ModalType.DOTS:
                setShowDotsModal(true);
                break;
        }
    };

    const formatSortString = (sortString: string) => {
        const separateOrderingString = sortString.split(',');
        setOrderByFields([ColumnType[separateOrderingString[0]]]);
        setOrderType(separateOrderingString[1].toUpperCase());
    };

    const dealFilters = () => {
        setFilter({
            ...filter,
            doctorsId: hiddenData.doctorsId.join(','),
            onCallsId: hiddenData.onCallsId.join(','),
            schedulesId: hiddenData.schedulesId.join(','),
            contractsId: hiddenData.contractsId.join(',')
        });
    };

    return (
        <>
            <div className="report__container">
                <div className="report__container--header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="back-button">
                            <div className="arrow-white" />
                        </div>
                        <div className="contract-detail__container--header-title">{t('report.admin.title')}</div>
                        <div className="select-infinity__container--buttons-dropdown">
                            <IconButton
                                ref={anchorDrop}
                                color="gray"
                                fontSize="14px"
                                isAlignCenter
                                width={'370px'}
                                height={'40px'}
                                filled
                                clickButton={() => setShowSelectDropdown(!showSelectDropdown)}
                            >
                                {!isEmpty(selectedReport) ? (
                                    <span>
                                        {selectedReport?.name}
                                        <img
                                            className="close-img"
                                            src={CloseImg}
                                            style={{ height: '15px', marginTop: '-5px', marginRight: '-2px' }}
                                            onClick={() => setSelectedReport(null)}
                                        />
                                    </span>
                                ) : (
                                    <span style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        {t('report.admin.buttonReport')}
                                        <img style={{ marginRight: '4px' }} src={ArrowImg} />
                                    </span>
                                )}
                            </IconButton>
                            {showSelectDropdown && <div className="select-infinity__container--gap" />}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '70px' }}>
                            {activeTab === Tab.ADMIN_REPORT && (
                                <IconButton
                                    color="green"
                                    isAlignCenter
                                    width={'134px'}
                                    height={'32px'}
                                    filled
                                    clickButton={() => handleShowModal(ModalType.SAVE)}
                                >
                                    {t('global.button.save')}
                                </IconButton>
                            )}
                        </div>
                        <img className="download-img" src={DownloadImg} onClick={() => getReportCsv()} />
                    </div>
                </div>
                {showNotifications && <NotificationModal />}
                <div className="report__container--header-second">
                    <MenuPage tabs={tabs} activeTab={activeTab} onChange={(code: any) => setActiveTab(code)} />
                </div>
                <div className="report__container--header-rule" />
                <div className="control-modal__container--body">
                    <div style={{ position: 'relative' }}>
                        {activeTab === Tab.ADMIN_REPORT && (
                            <AdminReportMain
                                defaultColumnsFilterReport={selectedReport?.data.fields?.join(',') ?? ''}
                                reportData={reportData}
                                filter={filter}
                                setFilter={setFilter}
                                predicate={predicate}
                                pageable={pageable}
                                setPageable={setPageable}
                                hiddenData={hiddenData}
                                setHiddenData={setHiddenData}
                                sortString={formatSortString}
                                setIsOnCallsWithDoctor={setIsOnCallsWithDoctor}
                                dealFilters={dealFilters}
                                isOnCallsWithDoctor={isOnCallsWithDoctor}
                                doctorsCleanFilter={doctorsCleanFilter}
                                onCallsCleanFilter={onCallsCleanFilter}
                                schedulesCleanFilter={schedulesCleanFilter}
                                contractsCleanFilter={contractsCleanFilter}
                                setDoctorsCleanFilter={value => setDoctorsCleanFilter(value)}
                                setOnCallsCleanFilter={value => setOnCallsCleanFilter(value)}
                                setSchedulesCleanFilter={value => setSchedulesCleanFilter(value)}
                                setContractsCleanFilter={value => setContractsCleanFilter(value)}
                            />
                        )}
                        {activeTab === Tab.MANAGE_REPORTS && (
                            <AdminReportList
                                handleShowModal={handleShowModal}
                                showDotsModal={showDotsModal}
                                setShowDotsModal={setShowDotsModal}
                                setActiveTab={setActiveTab}
                                setSelectedOption={setSelectedReport}
                                setIsFirstRender={() => setIsFirstRender(true)}
                            />
                        )}
                    </div>
                </div>
                <Footer />

                {/* ***** MODALS ***** */}
                <SaveModal
                    showModal={showSaveModal}
                    setShowModal={setShowSaveModal}
                    report={selectedReport}
                    predicate={predicate}
                    isOnCallsWithDoctor={isOnCallsWithDoctor}
                    doctorsCleanFilter={doctorsCleanFilter}
                    onCallsCleanFilter={onCallsCleanFilter}
                    schedulesCleanFilter={schedulesCleanFilter}
                    contractsCleanFilter={contractsCleanFilter}
                />
                <SelectInfinity
                    showModal={showSelectDropdown}
                    onCloseModal={() => setShowSelectDropdown(false)}
                    anchorEl={anchorDrop}
                    savedFiltersSelected={selectedReport}
                    setSavedFiltersSelected={value => {
                        setIsFirstRender(true);
                        setSelectedReport(value);
                    }}
                />
                <ModalCircularProgress isShowModal={isShowProgressModal} progress={progress >= 95 ? 99 : progress} />
            </div>
        </>
    );
};
export default AdminReport;
